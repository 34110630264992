







































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import CalendarIcon from '@/app/ui/assets/ics_o_calendar.vue'
import CheckBox from '../../../Payroll/components/CheckBox/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import { ValidationRule, required, requiredIf } from 'vuelidate/lib/validators'
import { TimeSlot } from '@/domain/entities/PickupTimeManagement'
import {
  ITimeSlotForm,
  ISlot,
} from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'
import { timeSlotFormSkeleton } from '@/app/infrastructures/misc/Constants/pickupTimeManagement'
import ChevronBullet from '@/app/ui/assets/ics_f_chevron_up_bullet.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PlusIcon from '@/app/ui/assets/ics_f_plus_circle.vue'
import TrashIcon from '@/app/ui/assets/ics_f_trash.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { Route } from '@/domain/entities/Route'
import NestedMultiSelect, {
  ILocation,
} from '../../components/NestedMultiSelect/index.vue'
import { CreateDynamicPickupRequest } from '@/data/payload/api/PickupTimeManagementRequest'
import controller from '@/app/ui/controllers/PickupTimeManagementController'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

interface IForm {
  routes: number[]
  isContinually: boolean
  periodeStart: Date | null
  periodeEnd: Date | null
  timeSlot: ITimeSlotForm[]
}

interface IValidation {
  form: {
    routes: { required: () => ValidationRule }
    periodeStart: {
      required: () => ValidationRule
      isMoreThanToday: (value: Date | null) => boolean
    }
    periodeEnd: {
      required: ValidationRule
      isMoreThanPeriodeStart: (value: Date | null) => boolean
    }
    timeSlot: {
      $each: {
        slots: {
          $each: {
            startTime: {
              required: ValidationRule
              moreThanPreviousStartTime: (val: Date, vm: ISlot) => boolean
            }
            endTime: {
              required: ValidationRule
              lessThanStartTime: (val: Date, vm: ISlot) => boolean
            }
            pickupTimeLimit: {
              minDigits: (val: number, vm: ISlot) => boolean | ValidationRule
              maxDigits: (val: number, vm: ISlot) => boolean | ValidationRule
              required: ValidationRule
            }
          }
        }
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    NestedMultiSelect,
    DateTimePickerV2,
    CalendarIcon,
    CheckBox,
    DataTableV2,
    ChevronBullet,
    WarningCircleIcon,
    TimePicker,
    TextInput,
    Button,
    PlusIcon,
    TrashIcon,
    ModalConfirm,
    ModalSuccess,
    LoadingOverlay,
  },
})
export default class PickupTimeManagementCreate extends Vue {
  routeController = routeController
  controller = controller

  timeSlotSkeleton = JSON.parse(JSON.stringify(timeSlotFormSkeleton))

  form: IForm = {
    routes: [],
    isContinually: false,
    periodeStart: null,
    periodeEnd: null,
    timeSlot: [],
  }

  days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
  hourOptions = Array.from({ length: 25 }, (_n, index) => index)

  modalCancelConfirmVisible = false
  modalSubmitConfirmVisible = false
  modalSuccessVisible = false

  routeList: ILocation[] = []

  created(): void {
    this.setInitialTimeSlotForm()
    this.routeController.getRouteList({})
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        routes: { required },
        periodeStart: {
          required,
          isMoreThanToday: this.isMoreThanToday,
        },
        periodeEnd: {
          required: requiredIf(() => {
            return !this.form.isContinually
          }),
          isMoreThanPeriodeStart: this.isMoreThanPeriodeStart,
        },
        timeSlot: {
          $each: {
            slots: {
              $each: {
                startTime: {
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.endTime || val.pickupTimeLimit) return true
                    if (!val.endTime || !val.pickupTimeLimit)
                      return parent?.isActive
                  }),
                  moreThanPreviousStartTime: (val, vm) => {
                    const prevRow = <ITimeSlotForm>(
                      this.form.timeSlot.find(slot =>
                        slot.slots.find(item => item.id === vm.id)
                      )
                    )
                    const currentIdx = <number>(
                      prevRow?.slots.findIndex(slot => slot.id === vm.id)
                    )
                    if (currentIdx > 0) {
                      if (
                        val &&
                        prevRow &&
                        prevRow.slots[currentIdx - 1].startTime
                      ) {
                        return (
                          val.getTime() >=
                          <number>(
                            prevRow?.slots[currentIdx - 1]?.startTime?.getTime()
                          )
                        )
                      }
                    }
                    return true
                  },
                },
                endTime: {
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.startTime || val.pickupTimeLimit) return true
                    if (!val.startTime || !val.pickupTimeLimit)
                      return parent?.isActive
                  }),
                  lessThanStartTime: (val, vm) => {
                    if (val && vm.startTime) {
                      return val.getTime() > vm.startTime.getTime()
                    }
                    return true
                  },
                },
                pickupTimeLimit: {
                  minDigits: (val, vm) => {
                    if (!vm.id) return true
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === vm.id
                      })
                    })
                    if (vm.startTime || vm.endTime)
                      return !val ? true : val >= 10
                    if (!vm.startTime || !vm.endTime)
                      return <boolean>parent?.isActive || !val
                        ? true
                        : val >= 10
                    return true
                  },
                  maxDigits: (val, vm) => {
                    if (!vm.id) return true
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === vm.id
                      })
                    })
                    if (vm.startTime || vm.endTime)
                      return !val ? true : val <= 999999
                    if (!vm.startTime || !vm.endTime)
                      return <boolean>parent?.isActive || !val
                        ? true
                        : val <= 999999
                    return true
                  },
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.startTime || val.endTime) return true
                    if (!val.startTime || !val.endTime) return parent?.isActive
                  }),
                },
              },
            },
          },
        },
      },
    }
  }

  get periodeStartPlaceholder(): string {
    if (this.form.periodeStart) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeStart.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeEndPlaceholder(): string {
    if (this.form.periodeEnd) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeEnd.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeStartErrMsg(): string {
    if (!this.isMoreThanToday(this.form.periodeStart)) {
      return 'Periode mulai harus lebih dari hari ini. Cek lagi'
    }
    return 'Periode mulai harus diisi'
  }

  get periodeEndErrMsg(): string {
    if (this.isPeriodeSame()) {
      return 'Periode berakhir tidak boleh sama dari periode mulai. Cek lagi'
    }
    if (!this.isMoreThanPeriodeStart(this.form.periodeEnd)) {
      return 'Periode berakhir harus lebih besar dari periode mulai. Cek lagi'
    }
    return 'Periode mulai harus diisi'
  }

  public onToggleSwitch(index: number): void {
    this.form.timeSlot[index].isActive = !this.form.timeSlot[index].isActive
    this.form.timeSlot[index].state = this.form.timeSlot[index].isActive
    this.form.timeSlot[index].isDisabled = false
  }

  public onDisableData(index: number, value: boolean): void {
    this.form.timeSlot[index].isDisabled = value
  }

  public onCollapse(index: number): void {
    this.form.timeSlot = this.form.timeSlot.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          state: !item.state,
        }
      }

      return item
    })
  }

  public addTimeSlot(index: number): void {
    this.form.timeSlot[index].slots.splice(
      this.form.timeSlot[index].slots.length - 1,
      0,
      {
        id: new Date().getTime(),
        endTime: undefined,
        pickupTimeLimit: undefined,
        startTime: undefined,
        timeZone: '',
      }
    )
  }

  public deleteTimeSlot(index: number, i: number): void {
    this.form.timeSlot[index].slots.splice(i, 1)
  }

  public onCheckContinually(): void {
    this.form.isContinually = !this.form.isContinually

    if (this.form.isContinually) {
      this.form.periodeEnd = null
    }
  }

  private isMoreThanPeriodeStart(value: Date | null): boolean {
    if (value && this.form.periodeStart) {
      return value.getTime() > this.form.periodeStart.getTime()
    }

    return true
  }

  private isPeriodeSame(): boolean {
    if (this.form.periodeEnd && this.form.periodeStart) {
      return (
        Utils.formatDate(this.form.periodeStart.toISOString(), 'DD/MM/YYYY') ===
        Utils.formatDate(this.form.periodeEnd.toISOString(), 'DD/MM/YYYY')
      )
    }

    return false
  }

  private isMoreThanToday(value: Date | null): boolean {
    if (value) {
      return (
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() < value.getTime()
      )
    }

    return true
  }

  private setInitialTimeSlotForm(): void {
    this.form.timeSlot = <ITimeSlotForm[]>this.timeSlotSkeleton.map(
      (item: TimeSlot) => {
        const data = item[Object.keys(item)[0] as keyof TimeSlot]
        return {
          isActive: true,
          slots: data?.slot?.map(item => item),
          state: true,
          isDisabled: false,
        }
      }
    )
  }

  public onTimeInput(
    val: {
      hour: string | number
      minute: string | number
    },
    index: number,
    i: number,
    type: 'startTime' | 'endTime'
  ): void {
    let newValue = new Date()
    if (val.hour === '24') {
      newValue.setHours(23, 59, 0, 0)
    } else if (parseInt(<string>val.minute) > 30) {
      newValue.setHours(parseInt(<string>val.hour), 30, 0, 0)
    } else {
      newValue.setHours(
        parseInt(<string>val.hour),
        parseInt(<string>val.minute),
        0,
        0
      )
    }
    this.form.timeSlot[index].slots[i][type] = newValue
  }

  public onInputPickupTimeLimit(val: string, index: number, i: number): void {
    if (parseInt(val) === 0) {
      this.form.timeSlot[index].slots[i].pickupTimeLimit = undefined
    }
  }

  public onCancel(): void {
    if (this.$v.form.$anyDirty) {
      this.modalCancelConfirmVisible = true
    } else {
      this.$router.push({ name: 'PickupTimeManagementTabPickupTime' })
    }
  }

  public onConfirmSubmit(): void {
    this.modalSubmitConfirmVisible = true
  }

  public onSubmit(): void {
    const payload = new CreateDynamicPickupRequest({
      periodeStart: <Date>this.form.periodeStart,
      routes: this.form.routes,
      timeSlot: this.form.timeSlot,
      periodeEnd: <Date>this.form.periodeEnd,
    })

    this.controller.createDynamicPickupTime(payload)
    this.modalSubmitConfirmVisible = false
  }

  private locationMapper(data: Route): ILocation {
    return {
      name: data.city || '',
      isChecked: false,
      routeId: data.routeId ? String(data.routeId) : '',
      isParentNotHidden: false,
      setParentNotHidden(state: boolean): void {
        this.children.forEach(child => {
          child.isParentNotHidden = state
          child.setParentNotHidden(state)
        })
      },
      isNotHidden(keyword: string): boolean {
        const find = (item: ILocation) => item.isNotHidden(keyword)

        if (this.name.toUpperCase().includes(keyword.toUpperCase())) {
          this.setParentNotHidden(true)
          return true
        } else {
          if (this.isParentNotHidden) {
            return true
          } else {
            this.setParentNotHidden(false)
          }
        }

        return (
          this.isParentNotHidden ||
          this.name.toUpperCase().includes(keyword.toUpperCase()) ||
          (this.children && this.children.some(find))
        )
      },
      getRouteId(): Array<number> {
        if (this.isChecked) {
          if (data.routeId && data.routeId !== 0) {
            return [data.routeId]
          } else if (data.routes && data.routes.length !== 0) {
            let routeIds: Array<number> = []

            this.children.forEach(child => {
              routeIds.push(...child.getRouteId())
            })

            return routeIds
          }
        } else if (data.routes && data.routes.length !== 0) {
          let routeIds: Array<number> = []

          this.children.forEach(child => {
            routeIds.push(...child.getRouteId())
          })

          return routeIds
        }

        return []
      },
      setIsChecked(state: boolean): void {
        this.isChecked = state
      },
      setCheckedNested(state: boolean): void {
        this.isChecked = state

        if (this.children.length !== 0) {
          this.children.forEach(item => {
            item.setCheckedNested(state)
          })
        }
      },
      isDisabled() {
        return data.routes
          ? !data.routes.map(route => route.isExists).includes(false)
          : <boolean>data.isExists
      },
      children: data.routes
        ? data.routes.map(child => {
            return this.locationMapper(child)
          })
        : [],
    }
  }

  @Watch('routeController.routeData')
  onRouteDataChanged(data: Route[]): void {
    if (data) {
      const mapRes: Array<ILocation> = data.map(
        (item): ILocation => {
          return this.locationMapper({
            ...item,
            routes: item.routes?.map((route: Route) => ({
              ...route,
              city: route.routeName,
            })),
          })
        }
      )
      this.routeList = mapRes
    }
  }

  @Watch('controller.isCreateDynamicPickupSuccess')
  onIsCreateDynamicPickupSuccessChange(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.controller.setIsCreateDynamicPickupSuccess(false)
    }
  }
}
