var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.isDropdownVisible)?_c('div',{staticClass:"backdrop-overlay",on:{"click":_vm.onClickOutside}}):_vm._e(),_c('div',{class:[
      'popup-button',
      _vm.isDropdownVisible
        ? 'rounded-t-md border border-b-0'
        : 'rounded-md border',
      _vm.disabled ? 'bg-gray-3' : 'bg-white' ],attrs:{"tabindex":"0"},on:{"click":_vm.onClickField,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickField($event)}}},[_c('div',{staticClass:"flex flex-wrap items-center gap-3"},[(_vm.selectedValues.length === 0)?_c('span',{staticClass:"text-gray-1"},[_vm._v(_vm._s(_vm.placeholder))]):_vm._l((_vm.selectedValues),function(name,index){return _c('div',{key:index,class:['tags', { disabled: _vm.disabled }],attrs:{"data-testid":("nested-multiselect__remove-item-" + index)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (function () {
            _vm.onRemoveItem(_vm.mainOptions, name)
          })($event)}}},[_c('span',[_vm._v(_vm._s(name))]),_c('CloseLine',{attrs:{"fill":"#78787A","width":"16","height":"16"}})],1)}),_c('div',{class:[
          'chevron',
          {
            'chevron-up': _vm.isDropdownVisible,
          } ]},[_c('CaretDownIcon',{attrs:{"fill":"#A7A8AA"}})],1)],2)]),(_vm.isDropdownVisible)?_c('div',{class:[
      'absolute flex flex-col bg-white w-full z-50 p-1 border-grayPensive rounded-b-md shadow-xl',
      _vm.isDropdownVisible ? 'border border-t-0' : 'border' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.debounceKeyword),expression:"debounceKeyword"}],staticClass:"outline-none w-full px-4 py-2",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.debounceKeyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.debounceKeyword=$event.target.value}}}),(_vm.isLoading)?_c('div',{staticClass:"flex justify-center items-center"},[_c('Loading')],1):_c('div',{staticClass:"max-h-72 overflow-y-auto"},_vm._l((_vm.options),function(data,index){return _c('TreeList',{key:index,attrs:{"data":data,"meta":_vm.treeListMeta,"disabled":data.isDisabled()}})}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }