


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import TreeList from '../TreeList/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'

export interface ILocation {
  name: string
  isChecked: boolean
  routeId: string
  isParentNotHidden: boolean
  setParentNotHidden: (state: boolean) => void
  isNotHidden: (keyword: string) => boolean
  getRouteId: () => Array<number>
  setIsChecked: (state: boolean) => void
  setCheckedNested: (state: boolean) => void
  isDisabled: () => boolean
  children: Array<ILocation>
}

@Component({
  name: 'NestedMultiSelect',
  components: {
    CaretDownIcon,
    CloseLine,
    CheckedIcon,
    ExpandIcon,
    TreeList,
    Loading,
  },
})
export default class NestedMultiselect extends Vue {
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: Array, default: () => [] }) value!: Array<number>
  @Prop({ type: Array, default: () => [] }) mainOptions!: Array<ILocation>
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) isLoading!: boolean

  isDropdownVisible = false
  selectedValues: Array<string> = []
  optionList: Array<ILocation> = []
  keyword = ''
  debounceKeyword = ''

  get options(): Array<ILocation> {
    return this.onFilterOption(this.mainOptions, this.keyword)
  }

  get treeListMeta(): string {
    const meta = {
      keyword: this.keyword,
    }
    return JSON.stringify(meta)
  }

  private getName(data: Array<ILocation>): Array<string> {
    let nameList: Array<string> = []

    data.forEach(item => {
      if (item.isChecked && !item.isDisabled()) {
        nameList.push(item.name)
      } else {
        nameList.push(...this.getName(item.children))
      }
    })

    return nameList
  }

  private onFilterOption(
    list: Array<ILocation>,
    keyword: string
  ): Array<ILocation> {
    if (keyword) {
      const find = (item: ILocation) =>
        item.name.toUpperCase().includes(keyword.toUpperCase()) ||
        (item.children && item.children.some(find))

      return list.filter(find)
    }

    return list
  }

  private getSelectedValues(data: Array<ILocation>): Array<number> {
    let idList: Array<number> = []

    data.forEach(item => {
      if (item.isChecked && item.routeId && !item.isDisabled()) {
        idList.push(Number(item.routeId))
      } else {
        idList.push(...this.getSelectedValues(item.children))
      }
    })

    return idList
  }

  private onClickField(): void {
    if (!this.disabled) {
      this.$emit('open')
      this.isDropdownVisible = !this.isDropdownVisible
    }
  }

  private onClickOutside(): void {
    this.keyword = ''
    this.debounceKeyword = ''
    this.isDropdownVisible = false
  }

  private onRemoveItem(list: Array<ILocation>, name: string): void {
    if (!this.disabled) {
      list.forEach(item => {
        if (item.name === name) {
          item.setCheckedNested(false)
        } else {
          this.onRemoveItem(item.children, name)
        }
      })
    }
  }

  private setKeyword(val: string): void {
    this.keyword = val
  }

  @Watch('mainOptions', { deep: true })
  onMainValueChange(): void {
    if (this.mainOptions.length !== 0) {
      this.selectedValues = [...this.getName(this.mainOptions)]
    }

    this.$emit('input', [
      ...new Set([...this.getSelectedValues(this.mainOptions)]),
    ])
  }

  @Watch('debounceKeyword')
  onKeywordChange = Utils.debounce((val: string) => {
    this.setKeyword(val)
  }, 400)
}
